import React, { useState } from "react"
import useFirestoreId from '../../modules/useFireStoreId';
import { Container } from 'react-bootstrap';
import './blogs.scss'
import Layout from '../../components/layout';
import SEO from '../../components/seo';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Input from '@material-ui/core/Input';
import { Button } from '@material-ui/core';
import moment from 'moment';
import _ from 'lodash';
import { makeStyles } from '@material-ui/core/styles';
import link from '../../images/link.png';
import ContactModal from '../../components/ContactModal';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';

import {
    FacebookIcon,
    LinkedinIcon,
    TwitterIcon,
    FacebookShareButton,
    LinkedinShareButton,
    TwitterShareButton
} from "react-share";

const useStyles = makeStyles((theme) => ({
    btRoot: {
        borderRadius: 20,
        fontSize: "10px",
        marginLeft: '5px',
        marginTop: '5px'
    },
    paper: {
        position: 'absolute',
        width: 400,
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
    link: {
        cursor: 'pointer',
        color: 'blue',
        fontSize: '12px'
    }
}));

function rand() {
    return Math.round(Math.random() * 20) - 10;
}

function getModalStyle() {
    const top = 50 + rand();
    const left = 50 + rand();

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    };
}

export default function Blogs() {
    const [open, setOpen] = useState(false);
    const [openCopy, setOpenCopy] = useState(false);
    const [modalStyle] = React.useState(getModalStyle);
    const [shareUrl, setShareUrl] = useState(typeof window !== 'undefined' ? window.location.href : "");
    const classes = useStyles();
    const url = typeof window !== 'undefined' ? window.location.pathname : '';
    const urlParams = url.split('/');
    const blogId = urlParams[urlParams.length - 2];
    const { doc } = useFirestoreId(blogId);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleCloseModal = () => {
        setOpenCopy(false)
    }

    const copyCodeToClipboard = () => {
        const el = document.createElement('input');
        el.value = shareUrl;
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
        setOpenCopy(true)
    }

    return (
        <Layout>
            <SEO title={doc ? "Blog | " + doc.title : "Blog"} description="" />
            <ContactModal open={open} handleClose={handleClose} />
            <div className="blogwrapper">
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={8} md={8}>
                        {doc &&
                            <div>
                                <div className="blog-heading">{doc.title}</div>
                                <p className="mb-4 blog-sub-heading">By <span style={{ color: "#4e069e" }}><strong>{doc.author}</strong></span> on {moment(doc.date).format('ll')}</p>
                                <div className="blog-main-text" dangerouslySetInnerHTML={{ __html: doc.content }}></div>
                            </div>
                        }
                        <Grid>
                            <Paper variant="outlined" className="aiInnovations" >
                                <h6>Are you ready to be part of AI Innovations?
                                </h6>
                                <h3>Start your trial and get <br></br>
1500 annotations for free</h3>
                                <Button onClick={handleOpen} className="contactUs">CONTACT US</Button>
                            </Paper>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={4} md={4}>
                        <Paper variant="outlined" className="newsletter" >
                            <h3>Subscribe for our Newsletter</h3>
                            {/* <h5>Email Address</h5>
                            <Input placeholder="" className="inputBox" /> */}
                            <Button onClick={handleOpen} className="btSubscribe">Subscribe</Button>
                        </Paper>
                        <Grid className="tags">
                            <h3>ARTICLE TAGS</h3>
                            {
                                !_.isEmpty(doc) &&
                                doc.tags.map((val, index) => {
                                    return (
                                        <Button key={index} className={classes.btRoot} variant="outlined" color="primary">
                                            {val}
                                        </Button>
                                    )
                                })
                            }
                            <h3>SHARE</h3>
                            <ul>
                                <li><FacebookShareButton url={shareUrl} ><FacebookIcon size={36}></FacebookIcon></FacebookShareButton></li>
                                <li><LinkedinShareButton url={shareUrl}><LinkedinIcon size={36} /></LinkedinShareButton></li>,
                                <li><TwitterShareButton url={shareUrl}><TwitterIcon size={36} /></TwitterShareButton></li>
                                {/* <li><img src={quara} alt="fb" /></li> */}
                                <li onClick={() => { copyCodeToClipboard() }}><img src={link} alt="fb" /></li>
                            </ul>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
            <Modal
                open={openCopy}
                onClose={handleClose}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <div style={modalStyle} className={classes.paper}>
                    <h2 id="simple-modal-title">Link copied!!!</h2>
                    <p className={classes.link} onClick={() => { handleCloseModal() }} id="simple-modal-description">
                        Close
      </p>
                </div>
            </Modal>
        </Layout>
    )
}


