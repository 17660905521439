import { useState, useEffect } from 'react'
import firebase from "gatsby-plugin-firebase"

export default function useFirestoreId(documentId) {
    const [doc, setDoc] = useState({});

    useEffect(() => {
        firebase.firestore().collection('fl_content').doc(documentId).get()
            .then(snapshot => {
                setDoc(snapshot.data())
            })
        return () => { }
    }, [documentId]);

    return { doc };
}
